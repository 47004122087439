import store from '@/store';
import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { getConfigEnv, jsonParse, sleep } from '@/utils/helpers';
import { decodeGorillaCompression } from '@/utils/testGorillaDecode.js';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import getNodeDescriptionCombinations from '@/graphql/cleanData/queries/getNodeDescriptionCombinations.graphql';
import getCleanTSDataTimeRangeV2Pad from '@/graphql/cleanData/queries/getCleanTSDataTimeRangeV2Pad.graphql';
import cleanDataApolloClient from '@/lib/appsync/cleanData';
import getWellDataTimeRange from '@/graphql/cleanData/queries/getWellDataTimeRange.graphql';
import axiosClient from '@/lib/rest/axiosClient';
import axios from 'axios';

@Module({
  dynamic: true,
  namespaced: false,
  name: 'padSignalsModule',
  store,
})
class PadSignalsModule extends VuexModule {
  currentSignals: any = [];

  aggregateSignals: any = [];

  compressorSignals: any = [];

  padSignals: any = [];

  padProductionSignals: any = [];

  colors: any = [
    '#FFC8A5',
    '#F21688',
    '#94ffdf',
    '#90e64e',
    '#FF94B8',
    '#94eaff',
    '#94c1ff',
    '#a894ff',
    '#b14ee6',
    '#94B0C6',
    '#41c4b0',
    '#94E2C6',
    '#a994c6',
    '#94c459',
    '#ffb194',
    '#c494ff',
    '#FF00FF',
    '#00FFFF',
    '#FF69B4',
    '#F0E68C',
    '#DC143C',
    '#00FF00',
    '#D8BFD8',
    '#ADFF2F',
    '#FFD700',
    '#FFA07A',
    '#FF6347',
    '#00FF7F',
    '#FFE4E1',
    '#7B68EE',
    '#6B8E23',
    '#9400D3',
    '#FFB6C1',
    '#FF4500',
    '#FF1493',
    '#8B0000',
    '#BA55D3',
    '#B0E0E6',
    '#2E8B57',
    '#FF8C00',
    '#C71585',
    '#40E0D0',
    '#7FFFD4',
    '#BDB76B',
    '#FA8072',
    '#8A2BE2',
    '#808000',
    '#9932CC',
    '#8B008B',
  ];

  padSelectedSignals: any = [];

  compressorSelectedSignals: any = [];

  aggregateSelectedSignals: any = [];

  padProductionSelectedSignals: any = [];

  signalChartTime = 60;

  individualProductionType = 'Gas';

  @Mutation
  resetModule(): void {
    this.aggregateSignals = [];
  }

  @Mutation
  resetSignals(type): void {
    this[type] = [];
  }


  @Mutation
  resetSignalData(type): void {
    this[type] =  this[type].map(signal => {
      signal.dataset = []
      signal.selected = false;
      return signal;
    });
  }

  @Mutation
  resetSelectedSignals(selectedType): void {
    this[selectedType] = [];
  }

  @Mutation
  setSelectedSignals(data, selectedType): void {
    this[selectedType] = data;
  }

  @Mutation
  setSignalChartTime(data): void {
    this.signalChartTime = data;
  }

  @Mutation
  setIndividualProductionType(data): void {
    this.individualProductionType = data;
  }

  @Mutation
  selectSignal({ signalName, selectedType }): void {
    if (this[selectedType].indexOf(signalName) > -1) {
      this[selectedType] = this[selectedType].filter((signal) => signal !== signalName);
    } else {
      this[selectedType].push(signalName);
    }
  }

  @Mutation
  selectPadProductionSignal({ signalName, selectedType, currentType }): void {
    if (this[selectedType].findIndex((name) => name.includes(signalName)) > -1) {
      this[selectedType] = this[selectedType].filter((signal) => !signal.includes(signalName));
    } else {
      this[selectedType] = [
        ...this[currentType]
          .filter((signal) => signal.legendName.includes(signalName))
          .map((data) => data.name),
      ];
    }
  }

  @Mutation
  addSignal({ signal, type }): void {
    const currentSignalsNames = this[type].map((s) => s.name);
    if (currentSignalsNames.includes(signal.name)) return;
    this[type].push(signal);
  }

  @Mutation
  updateSignal({ signal, type }): void {
    this[type] = this[type].filter((data) => data.name !== signal.name);
    this[type].push(signal);
  }

  abortControllerProduction: any = null


  @Mutation
  setProductionController(value): void {
    if(value === 'null'){
      this.abortControllerProduction = null
    }else if(value=== 'set') {
      this.abortControllerProduction =  axios.CancelToken.source();
    }else if(this.abortControllerProduction  && value === 'abort'){
      console.log('abortControllerSingals was aborted');
      this.abortControllerProduction.cancel("Canceling previous request due to new request.");
    }
  }


  @Action
  async getSignalDescriptions({ nodeID, filter }) {
    console.log(filter);
    if (filter === 'pad') {
      filter = 'pad_signals';
    }
    const {
      data: { get_clean_ts_data_node_description_combinations_v2: nodeData },
    }: any = await cleanDataApolloClient.query({
      query: getNodeDescriptionCombinations,
      variables: {
        operator: getConfigEnv('OPERATOR_PASCALED'),
        nodeid: nodeID,
        filter: filter,
      },
      fetchPolicy: 'network-only',
    });
    return jsonParse(nodeData)?.Description || [];
  }

  @Action
  async getSignalsForPad(input: any): Promise<void> {
    // data = 60
    const { data = 60, filter = 'pad', loadData = true, fetchSelectedSignals = [] } = input;
    const type = `${filter}Signals`;
    const selectedType = `${filter}SelectedSignals`;
    let updateExisting = false;
    if (fetchSelectedSignals && fetchSelectedSignals.length) {
      this.resetSignalData(type)
      this.resetSelectedSignals(selectedType);
      updateExisting = true;
    } else {
      this.resetSignals(type);
      this.resetSelectedSignals(selectedType);
    }

    let wellName =
      tasqsListModule.activeTasq?.wellName != null
        ? tasqsListModule.activeTasq?.wellName
        : assetsModule.activeTasq?.wellName;
    const fetchSignalQuery = getCleanTSDataTimeRangeV2Pad;
    let localOperator = getConfigEnv('OPERATOR_PASCALED').toLowerCase() === 'pdc' ? 'pdce' : getConfigEnv('OPERATOR_PASCALED');

    if(localOperator === 'Demo'){
      localOperator = 'demo'
    }

    if (!wellName) {
      return;
    }

    let signalsToFetch: any = [];

    if (updateExisting) {
      signalsToFetch = fetchSelectedSignals;
    } else {
      if(filter === 'pad'){

        // const tankSignals = await this.getSignalDescriptions({
        //   nodeID: wellName,
        //   filter: 'tank'
        // });
        // const facilitySignals = await this.getSignalDescriptions({
        //   nodeID: wellName,
        //   filter: 'facility'
        // });


        // signalsToFetch = [...facilitySignals,...tankSignals]
        signalsToFetch = ['Tank Level']
        // console.log(signalsToFetch);

      }else{
        // signalsToFetch = await this.getSignalDescriptions({
        //   nodeID: wellName,
        //   filter: filter
        // });
      if(filter === 'aggregate'){
        signalsToFetch.push( "Daily Aggregate")
        signalsToFetch.push('Separator Temperature')
        signalsToFetch.push('Separator Hi Setpoint')
        signalsToFetch.push('Separator Lo Setpoint')
        signalsToFetch.push( "Casing Pressure Aggregate")
        signalsToFetch.push( "Tubing Pressure Aggregate")
        signalsToFetch.push( "Static Pressure Aggregate")

      }
      console.log(filter);
      console.log(signalsToFetch);
      }

    }

    let fromTime = DateTime.utc()
      .minus({ days: data + tasqsListModule.fromNowBack })
      .toISO()
      .slice(0, -5);
    const toTime = DateTime.utc()
      .minus({ days: tasqsListModule.fromNowBack === 1 ? 0 : tasqsListModule.fromNowBack })
      .toISO()
      .slice(0, -5);

    fromTime = fromTime.split('T')[0];

    tasqsListModule.setChartFromTime(fromTime);
    tasqsListModule.setChartToTime(toTime);

    signalsToFetch.forEach(async (signal, idx) => {
      try {


        let color = this.colors[idx];
        if (signal.toLowerCase().includes('casing pressure')) {
          color = '#DB94C6';
        } else if (signal.toLowerCase().includes('tubing pressure')) {
          color = '#94FFB0';
        } else if (signal.toLowerCase().includes('inj. flowrate')) {
          color = '#FFCD5C'; // light orange yellow';
        } else if (signal.toLowerCase().includes('flowrate')) {
          color = '#e64e4e'; // light orange yellow';
        } else if (signal.toLowerCase().includes('static pressure')) {
          color = '#FFFF5D';
        } else if (signal.toLowerCase().includes('tp-lp')) {
          color = '#696969';
        } else if (signal.toLowerCase().includes('cp-tp')) {
          color = '#baa79b';
        } else if (signal.toLowerCase().includes('cp-lp')) {
          color = '#c2c2c2';
        } else if (signal.toLowerCase().includes('oil rate')) {
          color = '#2de6c1';
        } else if (signal.toLowerCase().includes('water rate')) {
          color = '#0077ff';
        } else if (signal.toLowerCase().includes('gas rate')) {
          color = '#f55d8b';
        }

        let selected = false;
        if (filter === 'compressor') {
          const selectedItems = [
            'compressor suction pressure',
            'compressor engine rpm',
            'discharge pressure',
            "suction temperature",
            "suction cylinder",
            "suction pressure",
            "engine rpm",
            "engine load",
            "compressor oil pressure",
            "engine oil pressure"
          ];
          if (selectedItems.includes(signal.toLowerCase())) {
            selected = true;
          }
        } else if (filter === 'aggregate') {
          const selectedItems = [
            'static pressure aggregate',
            'gas rate aggregate',
            'oil rate aggregate',
          ];
          if (selectedItems.includes(signal.toLowerCase())) {
            selected = true;
          }
        } else if (filter === 'pad') {
          const selectedItems = [
            "tank pressure",
            "tank level",
            "differential pressure",
            "line pressure",
            "separator pressure",
            "vert separator pressure"
          ]
          if (selectedItems.includes(signal.toLowerCase())) {
            selected = true;
          }
        }

        if (updateExisting) {
          selected = true;
        }



        if (loadData || selected) {

          if(signal === 'Daily Aggregate'){

        const typesOfDeferment = ['oil', 'gas']


        typesOfDeferment.forEach(async (typeDeferment) => {
          const defermentType = `${typeDeferment}_rate`
          const typeUpperCase = typeDeferment[0].toUpperCase() + typeDeferment.substring(1);
          const {data: productionGasRateData} =  await axiosClient.get('/deferments/descriptions',{
              params:{
                deferment_type:defermentType,
                node: wellName,
                from_date: fromTime.split('T')[0],
                to_date: toTime.split('T')[0],
                operator: localOperator,
                level: 'pad'
              }
            })



            if (productionGasRateData.description.length > 0) {
              productionGasRateData.description.forEach((des,i) => {

                  let updatedName = des
                  if(des === 'production'){
                    updatedName = `${typeUpperCase} Rate Aggregate`
                  }
                  else if(des === 'upper_thresh'){
                    updatedName = `Upper Thresh ${typeUpperCase} Rate Aggregate`
                  }

                  else if(des === 'lower_thresh'){
                    updatedName = `Lower Thresh ${typeUpperCase} Rate Aggregate`
                  }

                  else if(des === 'reference'){
                    updatedName = `Target ${typeUpperCase} Rate Aggregate`
                  }else{
                   return
                  }

                  if( updatedName.includes('Rate Aggregate')){
                    selected = true
                    if(updatedName.includes('Oil')){
                      color = '#2CE6C2'
                    }else if (updatedName.includes('Gas')){
                      color = '#f55d8b'
                    }
                  }

                  const signalLocal = {
                    id: idx,
                    name: updatedName,
                    dataset: productionGasRateData.values[i],
                    time: [],
                    unit: '',
                    color,
                    wellName,
                    selected,
                  };

                  console.log(signalLocal);
              if (updateExisting) {
                this.updateSignal({ signal:signalLocal, type });
              } else {
                this.addSignal({ signal: signalLocal, type });
              }


              })

            }
        })

          }else{

          const {data} = await axiosClient.post('/signals/node-data',{
            match: {
              operator: localOperator,
              nodeid: wellName,

            },
            desc: [signal],
            start_time: fromTime,
            end_time: toTime,
            freq: '30m',
            fillna_method: 'locf',
            gorilla_compress: false,
            raw: true,
            downsample_data: true,
          })


          const description =  data.description

          if (description.length > 0 && data.values.length) {
            const currentWellName =
            tasqsListModule.activeTasq?.wellName != null
              ? tasqsListModule.activeTasq?.wellName
              : assetsModule.activeTasq?.wellName;
          const date = new Date();
          date.toISOString();

          if (currentWellName !== wellName) {
            return;
          }
            description.forEach((element,i) => {




            if( element.includes('Rate Aggregate')){
              selected = true
              if(element.includes('Oil')){
                color = '#2CE6C2'
              }else if (element.includes('Gas')){
                color = '#f55d8b'
              }
            }
            const signalLocal = {
              id: idx,
              name: `${element}`,
              dataset: data.values[0],
              time: data.time[0],
              unit: '',
              color,
              wellName,
              data,
              selected,
            };


            if (updateExisting) {
              this.updateSignal({ signal:signalLocal, type });
            } else {
              this.addSignal({ signal: signalLocal, type });
            }
            });




          }
          }
               } else {
          const signalLocal = {
            id: idx,
            name: signal,
            dataset: [],
            time: [],
            unit: '',
            color,
            wellName,
            data,
            selected,
          };

          this.addSignal({ signal: signalLocal, type });
        }
      } catch (error) {
        console.log(`Error: Failed to ${signal} signal data on ${wellName}, for ${data} days`);
      }
    });
  }

  @Action
  async getPadWellProductionData({ tasq, data }) {
    this.setProductionController('abort')
    // Create a new AbortController for the new set of requests
    this.setProductionController('set')
    const { token } = this.abortControllerProduction;
    const filter = 'padProduction';
    const type = `${filter}Signals`;
    const selectedType = `${filter}SelectedSignals`;
    this.resetSelectedSignals(selectedType);
    this.resetSignals(type)
    let fromTime = DateTime.utc()
      .minus({ days: data + tasqsListModule.fromNowBack })
      .toISO()
      .slice(0, -5);
    const toTime = DateTime.utc()
      .minus({ days: tasqsListModule.fromNowBack === 1 ? 0 : tasqsListModule.fromNowBack })
      .toISO()
      .slice(0, -5);

    fromTime = fromTime.split('T')[0];

    if (tasq && tasq.wells) {
      const processWellData = async (wells, resources) => {
        wells.forEach(async (well, idx) => {
          const color = this.colors[idx];
          let localOperator = getConfigEnv('OPERATOR_PASCALED').toLowerCase() === 'pdc' ? 'pdce' : getConfigEnv('OPERATOR_PASCALED');


          if(localOperator === 'Demo'){
            localOperator = 'demo'
          }

          const results = await Promise.all(
            resources.map((resource,i) =>
              axiosClient.post('/signals/daily-production', {
                match: {
                  operator: localOperator,
                  nodeid: well.wellName,
                },
                desc: [resource],
                start_time: fromTime,
                end_time: toTime,
                freq: '30m',
                fillna_method: 'locf',
                gorilla_compress: false,
                raw: true,
                downsample_data: true,
                source: 'app'
              },{
                cancelToken: token
              })
            )
          );


          results.forEach((result, i) => {
            const { data } = result;
            const resourceType = resources[i].replace('Rate', '');
            const dataset = data.values;
            const signalLocal = {
              id: idx,
              name: `${resourceType}(${well.wellName})`,
              legendName: resourceType,
              dataset: dataset,
              time: data.time,
              unit: '',
              color: color,
              wellName: `${well.wellName}`,
              selected:resourceType.toLowerCase() === 'gas',
            };

            this.addSignal({ signal: signalLocal, type });
          });
        });
      };

      await processWellData(tasq.wells, ['GasRate']);
      sleep(3000).then(async () => {
        processWellData(tasq.wells, ['OilRate']);
        processWellData(tasq.wells, ['WaterRate']);
      })

    }
  }
}

export default getModule(PadSignalsModule);
